<template>
  <div class="mobileCustom">
    <div class="banner"></div>
    <div class="sec">
      <div class="title">智能定制服务</div>
      <div class="text">我们专业的定制服务团队结合客户实际需求与房间布局，</div>
      <div class="text texts">为客户定制专属的产品解决方案和场景设计。</div>
      <!-- 01 -->
      <div class="secBox">
        <img src="../../assets/mobile/index/custom1.png" class="img" />
        <div class="box">
          <div class="left">
            <div class="num">01</div>
          </div>
          <div class="right">
            <div class="top">
              <div class="name">定制服务咨询</div>
              <div class="text">您可以通过拨打我们的电话 400-000-7027</div>
              <div class="text texts">来快速了解我们提供的服务，或者与我们进</div>
              <div class="text texts">行合作咨询。</div>
            </div>
            <div class="bottom">
              <div class="dv">
                <div class="tit">便捷</div>
                <div class="txt">可致电人工客服快速了解合作信息</div>
              </div>
              <div class="dv">
                <div class="tit">高效</div>
                <div class="txt">可通过客服快速获取合作建议</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 02 -->
      <div class="secBox secBoxs">
        <img src="../../assets/mobile/index/custom2.png" class="img" />
        <div class="box">
          <div class="left">
            <div class="num">02</div>
          </div>
          <div class="right">
            <div class="top">
              <div class="name">定制和考察分析</div>
              <div class="text">确定合作意愿后，我们的工程师将上门为您</div>
              <div class="text texts">考察评估，与您沟通需求，并为您量身定制</div>
              <div class="text texts">智能酒店解决方案。</div>
            </div>
            <div class="bottom">
              <div class="dv">
                <div class="tit">专业</div>
                <div class="txt">我们的专业工程师直接为您服务</div>
              </div>
              <div class="dv">
                <div class="tit">高效</div>
                <div class="txt">一对一服务沟通更高效</div>
              </div>
              <div class="dv">
                <div class="tit">定制</div>
                <div class="txt">工程师与您沟通需求并量身定制智能</div>
                <div class="txt txts">酒店解决方案</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 03 -->
      <div class="secBox">
        <img src="../../assets/mobile/index/custom3.png" class="img" />
        <div class="box">
          <div class="left">
            <div class="num">03</div>
          </div>
          <div class="right">
            <div class="top">
              <div class="name">上门安装调试</div>
              <div class="text">我们的工程师将上门为您提供专业的安装与</div>
              <div class="text texts">调试服务。</div>
            </div>
            <div class="bottom">
              <div class="dv">
                <div class="tit">安装</div>
                <div class="txt">专业人员上门安装</div>
              </div>
              <div class="dv">
                <div class="tit">调试</div>
                <div class="txt">调试系统及设备至正常状态</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 04 -->
      <div class="secBox">
        <img src="../../assets/mobile/index/custom4.png" class="img" />
        <div class="box">
          <div class="left">
            <div class="num">04</div>
          </div>
          <div class="right">
            <div class="top">
              <div class="name">产品需求调试</div>
              <div class="text">我们将根据为您量身定制智能酒店解决方案，</div>
              <div class="text texts">上门或远程调试您想要的功能或联动场景。</div>
            </div>
            <div class="bottom">
              <div class="dv">
                <div class="tit">专业</div>
                <div class="txt">软件工程师联动调试</div>
              </div>
              <div class="dv">
                <div class="tit">定制</div>
                <div class="txt">工程师为您量身定制</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 05 -->
      <div class="secBox secBox5">
        <img src="../../assets/mobile/index/custom5.png" class="img" />
        <div class="box">
          <div class="left">
            <div class="num">05</div>
          </div>
          <div class="right">
            <div class="top">
              <div class="name">智能维护服务</div>
              <div class="text">BOT 超级主机具有远程检测和调试功能，</div>
              <div class="text texts">发现问题您可随时联系我们。</div>
            </div>
            <div class="bottom">
              <div class="dv">
                <div class="tit">智能</div>
                <div class="txt">远程即可发现问题</div>
              </div>
              <div class="dv">
                <div class="tit">高效</div>
                <div class="txt">可通过客服快速获取合作建议</div>
              </div>
              <div class="dv">
                <div class="tit">上门</div>
                <div class="txt">其他问题安排人工上门服务</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {}
};
</script>
<style scoped lang="less">
.mobileCustom {
  background-color: #fff;
}
.banner {
  width: 100%;
  height: 8rem;
  background-image: url(../../assets/mobile/index/customBanner.png);
  background-size: 100% 100%;
}
.sec {
  color: #333333;
  padding-top: 0.79rem;
  padding-bottom: 0.8rem;
  .title {
    font-size: 0.36rem;
    line-height: 0.36rem;
    font-weight: bold;
  }
  .text {
    margin-top: 0.39rem;
    font-size: 0.24rem;
    line-height: 0.24rem;
    font-weight: 400;
  }
  .texts {
    margin-top: 0.16rem;
  }
  .secBox {
    margin-top: 0.4rem;

    .img {
      display: block;
      width: 100%;
      height: 4.76rem;
    }
    .box {
      margin-top: 0.1rem;
      width: 100%;
      height: 4.76rem;
      display: flex;
      background-color: #f8f8f8;
      .left {
        width: 2rem;
        height: 100%;
        border-right: 0.02rem solid #fff;
        position: relative;
        .num {
          margin-left: -0.07rem;
          margin-top: 2.78rem;
          -webkit-text-stroke: 0.02rem rgba(255, 144, 0, 0.5);
          -webkit-text-fill-color: transparent;
          font-size: 1.3rem;
          text-shadow: 0.05rem 0.05rem 0.01rem rgba(255, 144, 0, 0.2);
          font-weight: bold;
        }
      }
      .right {
        flex: 1;
        color: #333333;
        text-align: left;
        .top {
          height: 2.74rem;
          border-bottom: 2px solid #fff;
          padding-left: 0.3rem;
          padding-right: 0.35rem;
          padding-top: 0.51rem;

          .name {
            font-size: 0.36rem;
            line-height: 0.36rem;
            font-weight: bold;
          }
          .text {
            margin-top: 0.29rem;
            font-size: 0.24rem;
            line-height: 0.24rem;
            font-weight: 400;
          }
          .texts {
            margin-top: 0.16rem;
          }
        }
        .bottom {
          .dv {
            display: flex;
            margin-left: 0.29rem;
            margin-top: 0.68rem;
            font-size: 0.24rem;
            line-height: 0.24rem;
            font-weight: 300;

            &:last-of-type {
              margin-top: 0.16rem;
            }
            .txt {
              color: #666;
              margin-left: 0.2rem;
            }
          }
        }
      }
    }
  }
  .secBoxs {
    .bottom {
      .dv {
        position: relative;
        margin-top: 0.17rem !important;
        &:first-of-type {
          margin-top: 0.27rem !important;
        }
        .txts {
          position: absolute;
          top: 0.4rem;
          left: 0.5rem;
        }
      }
    }
  }
  .secBox5 {
    .bottom {
      .dv {
        margin-top: 0.16rem !important;
        &:first-of-type {
          margin-top: 0.49rem !important;
        }
      }
    }
  }
}
</style>